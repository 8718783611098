

























import { Vue, Component } from "vue-property-decorator";
import { newsListApi } from "@/api";
@Component({
    filters: {
        filterTime(val: any) {
            return val && val.slice(5, 10);
        },
        extract(content: String) {
            if (!content) return;
            content = content.replace(/<.*?>/g, "");
            return content.replace(/&.*?;/g, "");
        },
    },
})
export default class product extends Vue {
    public tabIndex = 0;
    public list = [];
    public async mounted() {
        let { list } = await newsListApi({
            pageSize: 10,
            pageNum: 1,
        });
        this.list = list;
        console.log(this.list);
    }

    public handelJump(item: any) {
        this.$router.push({ path: "/newsDetail", query: { id: item.id } });
    }

    public metaInfo = {
        title: "新闻动态-品牌新闻-简单睡眠",
        meta: [
            {
                name: "简单睡眠新闻，品牌新闻，新闻中心，新闻动态",
                content: "简单睡眠全国现有220多家连锁店，分布在上海、浙江、江苏、福州、四川、重庆、湖北、陕西、云南，未来三年开遍全中国。",
            },
        ],
    };
}
